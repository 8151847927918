<template>
    <section id="timetable" class="section timetable">
        <div class="container">
            <div class="jt_row jt_row-fluid row">
                <span class="column-divider"></span>
                <div class="col-md-12 jt_col column_container">
                    <h1 class="font-title">
                        <div class="opening-hours">Opening Hours</div>
                    </h1>
                </div>

            <div class="col-md-12 jt_col column_container">
                    <div class="section-subtitle days">
                        Monday to Sunday
                    </div>
                    <div class="section-subtitle hours">
                        10:30am<br>~<br>8:30pm
                    </div>
                </div>

            </div>

            </div>
    </section>
</template>

<script>
    export default {
        name: "Timetable"
    }
</script>

<style scoped>

</style>
