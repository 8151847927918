import Vue from "vue";
import Components from "./components";
import "./scss/index.scss";
// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
// Install BootstrapVue take around 800kb, not import some of the plugin if you need the website to speed up.
//check https://bootstrap-vue.org/docs/components for components you might need (there are more components)
// iconPlugin is 300kb itself, make sure import individual icons as need
import {
  LayoutPlugin,
  ModalPlugin,
  NavPlugin,
  NavbarPlugin,
  DropdownPlugin,
  FormPlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormCheckboxPlugin,
  InputGroupPlugin,
  SpinnerPlugin,
  EmbedPlugin,
  IconsPlugin,
  SidebarPlugin,
  // ImagePlugin,
  // ButtonPlugin,
  // CardPlugin,
  // TablePlugin,
} from 'bootstrap-vue';
Vue.use(LayoutPlugin);
Vue.use(ModalPlugin);
Vue.use(NavPlugin);
Vue.use(NavbarPlugin);
Vue.use(DropdownPlugin);
Vue.use(FormPlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(InputGroupPlugin);
Vue.use(SpinnerPlugin);
Vue.use(EmbedPlugin);
Vue.use(IconsPlugin);
Vue.use(SidebarPlugin);
// Vue.use(ImagePlugin); // todo: compare it with lazy load functions, see if it is working properly
// Vue.use(ButtonPlugin);
// Vue.use(CardPlugin);
// Vue.use(TablePlugin);


// import Axios from "axios";
// import VueAxios from "vue-axios";
// import Vuelidate from "vuelidate";  //https://vuelidate.js.org/#sub-basic-form
// import '@fortawesome/fontawesome-free/css/all.css'
// import '@fortawesome/fontawesome-free/js/all.js'

Vue.config.productionTip = false;
// Vue.use(Vuelidate);
// Vue.use(VueAxios, Axios);

// Install BootstrapVue
// Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
// Vue.use(IconsPlugin);

import VueScrollTo from "vue-scrollto";
Vue.use(VueScrollTo, {
  container: "body",
  duration: 1000,
  easing: "ease",
  offset: -100,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
});

new Vue({
  el: "#app",
  delimiters: ["${", "}"],
  data: {
    showModal: false,
  },
  methods:{
  },
  mounted(){
    console.log("mounted");
  },
  updated(){
    console.log("updated");
  },
  search(){
    // console.log('qq')
  }
});
