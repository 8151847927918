<template>
    <b-breadcrumb>
        <b-breadcrumb-item :href="home">
            <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon>
            Home
        </b-breadcrumb-item>
        <b-breadcrumb-item :href="`${home}/menu`">Menu</b-breadcrumb-item>
        <b-breadcrumb-item v-if="state" :href="`${home}/food/${cat}`">{{cat}}</b-breadcrumb-item>
        <b-breadcrumb-item active v-if="state">{{product.title}}</b-breadcrumb-item>
    </b-breadcrumb>
</template>

<script>
    export default {
        name: "breadcrumb",
        props:['product','home','state','cat'],
        data() {
            return {
            }
        }
    }
</script>

<style scoped>

</style>
