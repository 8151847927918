<template>
    <div>
        <b-modal
                id="modal-search"
                ref="modal"
                @show="resetModal"
                @hidden="resetModal"
                @ok="handleOk"
                header-class="d-none"
                footer-class="d-none"
                body-bg-variant="dark"
                body-text-variant="primary"
        >
            <template v-slot:modal-header="">
                <div></div>
            </template>
            <template v-slot:modal-footer="">
                <div></div>
            </template>
            <template v-slot:default="{ close }" class="bg-dark">
                <b-icon class="close-icon" variant="primary" icon="x" @click="close()"></b-icon>
                <form ref="form" @submit.stop.prevent="handleSubmit">
                    <b-form-group
                            :state="inputState"
                            label="Search String"
                            label-for="name-input"
                            invalid-feedback="Name is required"
                    >
                        <b-input-group>
                            <b-form-input
                                    id="name-input"
                                    v-model="search"
                                    :state="inputState"
                                    class="bg-dark border-primary text-primary"
                                    required
                            ></b-form-input>
                            <b-input-group-append>
                                <button type="submit" class="btn btn-outline-primary">Search</button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </form>
            </template>
        </b-modal>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                search: '',
                inputState: null
            }
        },
        methods: {
            checkFormValidity() {
                const valid = this.$refs.form.checkValidity();
                this.inputState = valid;
                return valid
            },
            resetModal() {
                this.search = '';
                this.inputState = null
            },
            handleOk(bvModalEvt) {
                // Prevent modal from closing
                bvModalEvt.preventDefault();
                // Trigger submit handler
                this.handleSubmit()
            },
            handleSubmit() {
                // Exit when the form isn't valid
                if (!this.checkFormValidity()) {
                    return
                }
                // Hide the modal manually
                this.$nextTick(() => {
                    this.$bvModal.hide('modal-search')
                });

                window.location.href = siteUrl + "menu?q=" + this.search;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .modal-header{
        display: none;
    }

    .modal-content{
        .close-icon{
            font-size: 2rem;
            position: absolute;
            color: var(--primary);
            right: 0.5rem;
            top: 0.5rem;
            line-height: 1;
            padding: 0;
            height: 2rem;
            width: 2rem;
            margin:0;
            cursor: pointer;
        }
    }

    .modal-footer{
        display: none;
    }
</style>
